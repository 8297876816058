<template>
  <form @submit.prevent="submit">
    <div class="row q-gutter-md">
      <BrandAutocomplete
        creable
        label="Produttore"
        style="width: 250px; max-width: 250px"
        error-name="brand_id"
        :errors="errors"
        v-model="sparePart.brand"
      />

      <BaseInput
        v-model="sparePart.code"
        error-name="code"
        :errors="errors"
        style="width: 200px; max-width: 200px"
        label="Codice"
      />

      <BaseInput
        v-model="sparePart.name"
        error-name="name"
        :errors="errors"
        class="col-grow"
        label="Nome"
      />
    </div>

    <BaseInput
      class="q-my-md"
      type="textarea"
      v-model="sparePart.description"
      label="Descrizione"
    />

    <div class="row q-mb-lg q-col-gutter-md">
      <div class="col-6 col-md-3">
        <BaseInput
          clearable
          v-model="sparePart.price"
          error-name="price"
          type="number"
          step="0.05"
          :errors="errors"
          label="Prezzo"
        >
          <template v-slot:prepend>
            <q-icon name="mdi-currency-eur" />
          </template>
        </BaseInput>
      </div>

      <div class="col-6 col-md-3">
        <BaseInput
          clearable
          v-model="sparePart.cost"
          error-name="cost"
          type="number"
          step="0.05"
          :errors="errors"
          label="Costo"
        >
          <template v-slot:prepend>
            <q-icon name="mdi-currency-eur" />
          </template>
        </BaseInput>
      </div>

      <div class="col-6 col-md-3">
        <BaseInput
          v-model.number="sparePart.buffer"
          error-name="buffer"
          :errors="errors"
          type="number"
          min="0"
          label="Quantità disponibile"
        />
      </div>

      <div class="col-6 col-md-3">
        <BaseInput
          v-model.number="sparePart.min_buffer"
          error-name="min_buffer"
          :errors="errors"
          type="number"
          min="0"
          label="Scorta minima"
        />
      </div>
    </div>

    <!-- buttons -->
    <slot />
  </form>
</template>

<script>
import useVModel from '../../hooks/vModel'
import BrandAutocomplete from '../brands/BrandAutocomplete.vue'

export default {
  name: 'SparePartForm',

  components: {
    BrandAutocomplete,
  },

  props: {
    value: {
      type: Object,
      default: () => {},
    },

    errors: {
      type: Object,
    },
  },

  setup(props, ctx) {
    const { vModel: sparePart } = useVModel(props.value, ctx)

    return {
      sparePart,
    }
  },
}
</script>
