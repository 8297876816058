import { computed } from '@vue/composition-api'

export default function useVModel( value, ctx )
{
  const vModel = computed({
    get: () => value,
    set: ( value ) => {
      ctx.emit( 'input', value )
    }
  })

  return { vModel }
}
