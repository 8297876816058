<template>
  <BaseCard subtitle="Storico prezzi">
    <BaseBanner
      v-if="!data.length"
      icon="mdi-information-outline"
      message="Nessuno storico disponibile"
    />

    <q-markup-table v-else separator="cell" flat bordered dense>
      <thead>
        <tr>
          <th class="text-left">Data e ora modifica</th>
          <th class="text-right">Costo</th>
          <th class="text-right">Prezzo</th>
        </tr>
      </thead>
      <tbody>
        <template v-for="item in data">
          <tr :key="`history_${item.id}`">
            <td class="text-left">
              {{ formatDate(item.created_at, 'DD/MM/YYYY, HH:mm') }}
            </td>
            <td class="text-right">{{ formatMoney(item.cost) }}</td>
            <td class="text-right">{{ formatMoney(item.price) }}</td>
          </tr>
        </template>
      </tbody>
    </q-markup-table>
  </BaseCard>
</template>

<script>
import { date } from 'quasar'
import { formatMoney } from '../../hooks/helpers.js'

export default {
  name: 'SparePartsPricesTable',

  props: {
    data: {
      type: Array,
    },
  },

  setup() {
    const { formatDate } = date
    return {
      formatDate,
      formatMoney,
    }
  },
}
</script>
