<template>
  <BaseCard :multi-section="data.length > 0" subtitle="Ordini fornitore">
    <BaseBanner
      v-if="!data.length"
      icon="mdi-information-outline"
      message="Nessuno storico disponibile"
    />

    <q-table
      v-else
      class="wrapped"
      flat
      square
      hide-bottom
      :columns="columns"
      :data="data"
    >
    </q-table>

    <template #footer>
      <BaseBtn label="Registra nuovo" icon="mdi-plus" @click="$emit('create')">
        <q-tooltip>Inserisci un nuovo ordine fornitore</q-tooltip>
      </BaseBtn>
    </template>
  </BaseCard>
</template>

<script>
import { formatMoney } from '../../hooks/helpers.js'
import { date } from 'quasar'

export default {
  name: 'SparePartsOrdersTable',

  props: {
    data: {
      type: Array,
    },
  },

  setup() {
    const columns = [
      {
        name: 'created_at',
        label: 'Data',
        align: 'left',
        field: 'created_at',
        sortable: true,
        format: (value) => date.formatDate(value, 'DD/MM/YYYY'),
        style: 'width:80px',
      },
      {
        name: 'quantity',
        label: 'Quantità',
        field: 'quantity',
        align: 'right',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'cost',
        label: 'Costo',
        align: 'right',
        field: 'cost',
        sortable: true,
        format: (value) => formatMoney(value),
        style: 'width:80px',
      },
      {
        name: 'supplier',
        label: 'Fornitore',
        align: 'left',
        field: 'supplier',
      },
    ]

    return {
      columns,
    }
  },
}
</script>
