<template>
  <BaseSelect
    use-input
    input-debounce="0"
    ref="select"
    v-bind="{ label: 'Cerca tra i fornitori', ...$attrs }"
    v-on="{ filter, ...$listeners }"
    :value="value"
    :options="options"
    :display-value="displayValue"
    @input-value="inputValue"
  >
    <template #prepend>
      <q-icon name="mdi-magnify" />
    </template>

    <template #no-option>
      <q-item>
        <q-item-section
          class="text-grey justify-between items-center"
          style="flex-direction: row !important"
        >
          <span class="q-mr-sm">
            Nessun risultato per "<strong>{{ query }}</strong
            >"
          </span>
          <BaseBtn
            v-if="creable"
            @click="create"
            label="Crea"
            icon="mdi-plus"
          />
        </q-item-section>
      </q-item>
    </template>

    <template v-slot:option="scope">
      <q-item v-bind="scope.item.itemProps" v-on="scope.item.itemEvents">
        <q-item-section>
          <q-item-label>{{ scope.item.opt.name }}</q-item-label>
        </q-item-section>
      </q-item>
    </template>
  </BaseSelect>
</template>

<script>
import { useActions, useGetters } from '../../hooks/store.js'
import { computed, ref } from '@vue/composition-api'

export default {
  name: 'SupplierAutocomplete',
  inheritAttrs: false,

  props: {
    creable: Boolean,
    inputPreserveValue: Boolean,
    value: {
      type: Object,
    },
  },

  setup(props, { emit, refs }) {
    //operation types
    const suppliers = useActions('supplier', ['load', 'store'])
    const { filter: filterSuppliers } = useGetters('supplier', ['filter'])
    suppliers.load()

    const query = ref('')
    const options = computed(() => {
      return filterSuppliers(query.value).value
    })

    const displayValue = computed(() => {
      return props?.value?.name ?? ''
    })

    function filter(query, update) {
      update(filterSuppliers(query))
    }

    function inputValue(value) {
      query.value = value

      if (!props.inputPreserveValue && value.length > 0) emit('input', {})
    }

    function create() {
      suppliers.store({ name: query.value }).then((value) => {
        //clear query
        refs.select.$refs.select.updateInputValue('')
        emit('input', value)
      })
    }

    return {
      displayValue,
      filter,
      inputValue,
      query,
      create,
      options,
    }
  },
}
</script>
