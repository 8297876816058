<template>
  <q-table
    class="wrapped"
    flat
    square
    binary-state-sort
    v-bind="$attrs"
    v-on="$listeners"
    :columns="columns"
  >
    <template #top>
      <SparePartListFilters
        :value="filters"
        @input="(filters) => $emit('filter', filters)"
      />
    </template>

    <template v-slot:body="props">
      <q-tr
        @click="$emit('row-click', $event, props.row)"
        :class="
          'cursor-pointer' +
          (props.row.min_buffer > 0 && props.row.buffer <= props.row.min_buffer
            ? ' bg-orange-2'
            : '')
        "
      >
        <q-td v-for="col in props.cols" :key="col.name" :props="props">
          {{ col.value }}
        </q-td>
      </q-tr>
    </template>
  </q-table>
</template>

<script>
import SparePartListFilters from './SparePartListFilters.vue'

export default {
  name: 'SparePartTableList',

  components: {
    SparePartListFilters,
  },

  props: {
    filters: {
      type: Object,
    },
  },

  setup() {
    const columns = [
      {
        name: 'id',
        label: 'ID',
        field: 'id',
        align: 'left',
        style: 'width:38px',
      },
      {
        name: 'brand',
        label: 'Produttore',
        align: 'left',
        field: (row) => row.brand.name,
        style: 'width:38px',
      },
      {
        name: 'code',
        label: 'Codice',
        field: 'code',
        align: 'left',
        sortable: true,
      },
      {
        name: 'name',
        label: 'Nome',
        field: 'name',
        align: 'left',
        sortable: true,
      },
      {
        name: 'description',
        label: 'Descrizione',
        field: 'description',
        align: 'left',
      },
      {
        name: 'buffer',
        label: 'Quantità',
        field: 'buffer',
        sortable: true,
        style: 'width:38px',
      },
      {
        name: 'min_buffer',
        label: 'Scorta mimina',
        field: 'min_buffer',
        sortable: true,
        style: 'width:38px',
      },
    ]

    return {
      columns,
    }
  },
}
</script>
