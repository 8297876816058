<template>
  <form @submit.prevent="submit">
    <div class="row q-mb-md">
      <div class="col-6 col-md-4">
        <SupplierAutocomplete
          creable
          label="Fornitore"
          error-name="supplier_id"
          :errors="errors"
          v-model="state.editable.supplier"
        />
      </div>
    </div>

    <div class="row q-mb-md">
      <div class="col-6 col-md-4">
        <BaseInput
          clearable
          v-model="state.editable.cost"
          error-name="cost"
          type="number"
          step="0.05"
          :errors="errors"
          label="Costo"
        >
          <template v-slot:prepend>
            <q-icon name="mdi-currency-eur" />
          </template>
        </BaseInput>
      </div>
    </div>

    <div class="row q-mb-md">
      <div class="col-6 col-md-4">
        <BaseInput
          v-model.number="state.editable.quantity"
          error-name="quantity"
          :errors="errors"
          type="number"
          min="0"
          label="Quantità"
        />
      </div>
    </div>

    <div class="row q-mb-md">
      <div class="col-6 col-md-4">
        <BaseDatePicker
          v-model="state.editable.created_at"
          label="Data invio"
          clearable
          clear-icon="close"
        />
      </div>
    </div>

    <BaseBtn label="Salva" class="q-mr-md" @click="store()" />

    <BaseBtn label="Annulla" color="red" @click="$emit('undo')" />
  </form>
</template>

<script>
import useApiCrud from '../../hooks/apiCrud.js'
import SupplierAutocomplete from '../suppliers/SupplierAutocomplete.vue'
import { onMounted } from '@vue/composition-api'
import SparePartOrders from '../../apis/sparePartOrders.js'
import { date } from 'quasar'

export default {
  name: 'SparePartOrderForm',

  components: {
    SupplierAutocomplete,
  },

  props: {
    sparePart: {
      type: Object,
      default: () => {},
    },
  },

  setup(props, ctx) {
    const { errors, state, store, setDetail } = useApiCrud({
      api: SparePartOrders,
      ctx,
      routed: false,
      events: {
        //add maintenance id
        beforeStore(data) {
          return { ...data, spare_part_id: props.sparePart.id }
        },
        store(response) {
          //route to list
          ctx.emit('store', response.data)
          ctx.root.$router.push({ name: 'sparePartShow' })
          return false
        },
      },
    })

    //set data
    onMounted(() =>
      setDetail({
        supplier: { id: null },
        cost: 0,
        quantity: 1,
        created_at: date.formatDate(new Date(), 'YYYY-MM-DD'),
      })
    )

    return {
      errors,
      state,
      store,
    }
  },
}
</script>
