<template>
  <BasePage
    padding
    title="Ricambi"
    subtitle="gestione magazzino"
    :breadcrumb="$route.matched"
  >
    <template #actions>
      <q-btn round color="pink" icon="mdi-plus" @click="show('create')">
        <q-tooltip>Nuovo ricambio</q-tooltip>
      </q-btn>
    </template>

    <!-- detail -->
    <template v-if="detailShow">
      <BaseCard :header="detail.name" v-if="detailEdit">
        <SparePartForm class="q-pa-md" :errors="errors" v-model="editable">
          <BaseBtn
            label="Salva"
            class="q-mr-md"
            @click="save()"
            :disabled="!detailDirty"
          />

          <BaseBtn label="Annulla" color="red" @click="show()" />
        </SparePartForm>
      </BaseCard>

      <!-- order -->
      <BaseCard
        v-else-if="$route.params.action == 'order'"
        :header="`${detail.code} - ${detail.name}`"
        subtitle="Nuovo ordine"
      >
        <SparePartOrderForm
          class="q-pa-md"
          :spare-part="detail"
          @store="(item) => detail.orders.push(item)"
          @undo="show()"
        />
      </BaseCard>

      <template v-else>
        <SparePartCard
          title=" "
          :header="`${detail.code} - ${detail.name}`"
          :spare-part="detail"
        >
          <template #footer>
            <BaseBtn label="Modifica" icon="mdi-pencil" @click="edit()" />

            <BaseBtn
              label="Elimina"
              icon="mdi-delete-outline"
              color="negative"
              @click="destroy()"
            />
          </template>

          <BaseModal
            confirm
            v-model="detailDestroy"
            @close="show()"
            @confirm="apiDestroy(detail.id)"
            title="Conferma richiesta"
            label="Elimina"
            ko-label="Annulla"
          >
            Rimuovere il ricambio
            <strong> {{ detail.code }} - {{ detail.name }} </strong>?
          </BaseModal>
        </SparePartCard>

        <SparePartsOrdersTable :data="detail.orders" @create="order" />

        <SparePartsPricesTable :data="detail.prices" />
      </template>
    </template>

    <!-- table -->
    <SparePartTableList
      v-else
      :data="data"
      :loading="loading"
      :pagination.sync="pagination"
      :filters="filters"
      @filter="(newFilters) => (filters = newFilters)"
      @row-click="(_, row) => show(row.id)"
      @request="onRequest"
    />
  </BasePage>
</template>

<script>
import SpareParts from '../apis/spareParts.js'
import SparePartCard from '../components/spare-parts/SparePartCard.vue'
import SparePartForm from '../components/spare-parts/SparePartForm.vue'
import SparePartOrderForm from '../components/spare-parts/SparePartOrderForm.vue'
import SparePartTableList from '../components/spare-parts/SparePartTableList.vue'
import useApiCrudTable from '../hooks/apiCrudTable.js'
import { computed, toRefs, watch } from '@vue/composition-api'
import useNotify from '../hooks/notify.js'
import { useActions } from '../hooks/store.js'
import SparePartsPricesTable from '../components/spare-parts/SparePartsPricesTable.vue'
import SparePartsOrdersTable from '../components/spare-parts/SparePartsOrdersTable.vue'

export default {
  name: 'SpareParts',

  components: {
    SparePartCard,
    SparePartForm,
    SparePartOrderForm,
    SparePartTableList,
    SparePartsOrdersTable,
    SparePartsPricesTable,
  },

  setup(_, ctx) {
    const { setDirty } = useActions(['setDirty'])

    const hasFromRequest = computed(
      () => ctx.root.$route.query.from_request !== undefined
    )

    const {
      close,
      errors,
      loading,
      onRequest,
      state,
      show,
      edit,
      destroy,
      apiDestroy,
      save,
    } = useApiCrudTable({
      api: SpareParts,
      ctx,
      events: {
        destroy(response) {
          const index = state.data.findIndex(
            (item) => item.id == state.detail.id
          )
          state.data.splice(index, 1)

          success('Eliminazione completata')
          return response
        },
        beforeStore(editable) {
          const data = { ...editable }

          if (hasFromRequest.value) {
            data.from_request = parseInt(ctx.root.$route.query.from_request)
          }

          return data
        },
        store(response) {
          //skip dirty alert after store new item
          setDirty(false)
          if (hasFromRequest.value) {
            const id = ctx.root.$route.query.from_request
            ctx.root.$router.push({
              name: 'sparePartRequestShow',
              params: { id },
              query: {},
            })
            return {}
          }
          show(response.data.id)
          return response
        },
        update(response) {
          success('Modifiche registrate')
          return response
        },
      },
      routes: {
        create: 'sparePartCreate',
        destroy: 'sparePartDestroy',
        edit: 'sparePartEdit',
        index: 'spareParts',
        show: 'sparePartShow',
      },
      filters: {
        sort: 'code',
        dir: 'asc',
        query: '',
      },
    })

    const { success } = useNotify()

    //breadcrumb name
    watch(
      () => ctx.root.$route.params.id,
      () => {
        if (ctx.root.$route.name == 'sparePartShow') {
          ctx.root.$route.meta.breadcrumb.label = '...'
        }
      }
    )
    watch(
      () => state.detail,
      (value) => {
        if (ctx.root.$route.name == 'sparePartShow') {
          ctx.root.$route.meta.breadcrumb.label = `${value.code} - ${value.name}`
        }
      }
    )

    function order() {
      ctx.root.$router.push({
        name: 'sparePartOrder',
      })
    }

    return {
      apiDestroy,
      close,
      destroy,
      edit,
      errors,
      loading,
      onRequest,
      order,
      save,
      show,
      ...toRefs(state),
    }
  },
}
</script>
